<template>
    <front-layout>
        <v-form class="flex-wrapper-full-background d-flex  fill-height" style="width: 100%" ref="form">
            <v-card class="mx-auto my-auto pa-5" max-width="900" min-width="500" raised>
                <v-card-title>{{ $t('auth.login') }}</v-card-title>
                <v-card-text>
                    <v-container>
                        <div>
                            <v-row>
                                <v-col>
                                    <v-text-field :error-messages="errors.email"
                                                  :label="$t('auth.email')"
                                                  :rules="rules_email"
                                                  type="email"
                                                  @input="btn_active()"
                                                  v-model="email"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field :error-messages="errors.password"
                                                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                                  :type="show_password ? 'text' : 'password'"
                                                  :label="$t('auth.password')"
                                                  :rules="[rules_password.required, rules_password.min]"
                                                  error
                                                  v-model="password"
                                                  @input="btn_active()"
                                                  @click:append="show_password = !show_password"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>

                                </v-col>
                                <!--     <v-col>
                                         <login-with-github />
                                     </v-col>
                                     <v-col>
                                         <login-with-google />
                                     </v-col>-->
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center justify-space-around">
                    <div>
                        <div>
                            <v-btn @click="handleLogin" :disabled="!btn_disabled">{{ $t('auth.login') }}</v-btn>
                        </div>
                        <div class="mt-15 ">
                            <v-btn x-small text :to="{name: 'forgot-password'}">{{ $t('auth.forgot_password') }}</v-btn>
                            <v-btn x-small text :to="{name: 'sign-up'}">{{ $t('auth.sign_up') }}</v-btn>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-form>
    </front-layout>
</template>

<script>
    /*    import LoginWithGithub from "../components/LoginWithGithub";
        import LoginWithGoogle from "../components/LoginWithGoogle";*/
    import FrontLayout from "../layouts/FrontLayout";

    export default {
        name: "Login",
        components: {FrontLayout},
        /*        components: {LoginWithGithub, LoginWithGoogle},*/
        data: function () {
            return {
                rules_email: [
                    value => !!value || 'Required.',
                    value => (value && value.length >= 6) || 'Min 6 characters',
                    value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                ],
                rules_password: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 6 || 'Min 6 characters'
                },
                email: '',
                password: '',
                errors: {},
                show_password: false,
                btn_disabled: false
            }
        },
        methods: {
           async handleLogin() {
                const {email, password} = this;
                if (this.validate()) {
                    this.$auth.login({data: {email, password}, redirect: null})
                        .then(res => {
                            let route = res.data.data.roles.findIndex(role => role === 'admin') > -1
                                ? this.$auth.options.loginData.redirect.admin
                                : this.$auth.options.loginData.redirect.other

                            this.$router.push(route)
                        })
                        .catch((error) => {
                            this.errors = error.response.data.errors;
                        })
                }
            },
            validate () {
                return this.$refs.form.validate()
            },
            btn_active () {
                this.btn_disabled = this.validate()
            }
        },
    }
</script>

<style scoped lang="scss">

</style>
